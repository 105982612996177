import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.footer`
  margin: 0.2rem 0 0.1rem 0;
  padding: 1rem ${props => props.theme.spacer.horizontal};
  text-align: center;
  color: ${props => props.theme.colors.grey};
  a {
    text-decoration: none;
    color: ${props => props.theme.brand.primary};
  }
`

const Footer = () => <Wrapper data-testid="footer">Version mai 2019 – Pieu de Longueuil, Québec</Wrapper>

export default Footer
