module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  siteTitle: 'Service pastoral', // Navigation and Site Title
  siteTitleAlt: 'Service pastoral - Ressources interactives', // Alternative Site title for SEO
  siteHeadline: 'Une serie de fiches aidant les membres dans leur service aupès des autres', // Headline for schema.org JSONLD
  siteTitleShort: 'Service pastoral', // short_name for manifest
  siteUrl: 'https://servicepastoral.ca', // Domain of your site. No trailing slash!
  siteLanguage: 'fr', // Language Tag on <html> element
  siteLogo: '/logo.png', // Used for SEO and manifest
  siteDescription: 'Un outil pour les frères soeurs qui souhaitent travailler dans le service pastoral',
  author: 'Ben Gonzalez', // Author for schema.org JSONLD

  // siteFBAppID: '123456789', // Facebook App ID - Optional
  userTwitter: '@emma', // Twitter Username
  ogSiteName: 'emma', // Facebook Site Name
  ogLanguage: 'fr_CA',
  googleAnalyticsID: 'UA-141320040-1',

  // Manifest and Progress color
  themeColor: '#3498DB',
  backgroundColor: '#2b2e3c',
}
