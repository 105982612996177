import React from 'react'
import PropTypes from 'prop-types'
import { Link, navigate } from 'gatsby'
import { animated } from 'react-spring'
import styled from 'styled-components'
import Img from 'gatsby-image'

const Item = styled(animated.div)`
  position: relative;
  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }
`

const Content = styled.div`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  a {
    color: #fff;
    height: 20%;
    left: 0;
    opacity: 0.85;
    padding: 1.65rem 1rem;
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 10;
    transition: all 0.3s ease-in-out;
    text-decoration: none;

    &:hover {
      color: #fff;
      opacity: 0.85;
      height: 100%;
      padding: 2rem;
      text-decoration: none;
      h2 {
        transition: all 0.3s ease-in-out;
        font-size: 36px;
      }
    }
  }
  h2 {
    margin-top: 0;
    font-size: 21px;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  }
`

const ImageWrapper = styled.div`
  > div {
    height: 100%;
    left: 0;
    position: absolute !important;
    top: 0;
    width: 100%;
    cursor: pointer;

    > div {
      position: static !important;
    }
  }
`

const Overlay = styled.div`
  background-color: ${props => props.theme.brand.primary};
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -2;
`

const TracedGlow = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.05;
  filter: invert(100%);
  z-index: -1;
`

const Service = styled.div`
  opacity: 0.8;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
`

const ProjectItem = ({ node, style, testid }) => {
  const navigateTo = slug => {
    navigate(slug)
  }

  return (
    <Item key={node.fields.slug} style={style} data-testid={testid}>
      <Content>
        <ImageWrapper onClick={() => navigateTo(node.fields.slug)}>
          <Img fluid={node.frontmatter.cover.childImageSharp.fluid} />
        </ImageWrapper>
        <Link to={node.fields.slug}>
          <Overlay style={{ backgroundColor: node.frontmatter.color }} />
          <h2>{node.frontmatter.client}</h2>
        </Link>
      </Content>
    </Item>
  )
}

export default ProjectItem

ProjectItem.propTypes = {
  node: PropTypes.object.isRequired,
  style: PropTypes.object.isRequired,
  testid: PropTypes.string.isRequired,
}
