import styled from 'styled-components'

const Hero = styled.section`
  position: relative;
  margin-top: -4rem;
  height: ${props => (props.single ? '30vw' : '40vw')};
  overflow: hidden;
  padding-bottom: 300px;
  margin-bottom: ${props => (props.single ? '1rem' : '2rem')};
  // ***************************  M : medium devices ***************************  //
  @media (orientation: portrait) and (max-width: ${props => props.theme.breakpoints.m}),
    (orientation: portrait) and (max-device-width: ${props => props.theme.breakpoints.m}) {
    height: ${props => (props.single ? '40vw' : '60vw')};
  }
  @media (orientation: landscape) and (max-width: ${props => props.theme.breakpoints.m}),
    (orientation: landscape) and (max-device-width: ${props => props.theme.breakpoints.m}) {
    height: ${props => (props.single ? '30vw' : '40vw')};
  }
  // ***************************  S : small devices ***************************  //
  @media (orientation: portrait) and (max-width: ${props => props.theme.breakpoints.s}),
    (orientation: portrait) and (max-device-width: ${props => props.theme.breakpoints.s}) {
    height: ${props => (props.single ? '350px' : '500px')};
  }
  @media (orientation: landscape) and (max-width: ${props => props.theme.breakpoints.s}),
    (orientation: landscape) and (max-device-width: ${props => props.theme.breakpoints.s}) {
    height: ${props => (props.single ? '350px' : '330px')};
  }
  // ***************************  XS: small devices ***************************  //
  @media (orientation: portrait) and (max-width: ${props => props.theme.breakpoints.xs}),
    (orientation: portrait) and (max-device-width: ${props => props.theme.breakpoints.xs}) {
    height: 420px;
    margin-top: -6.5rem;
  }
  @media (orientation: landscape) and (max-width: ${props => props.theme.breakpoints.xs}),
    (orientation: landscape) and (max-device-width: ${props => props.theme.breakpoints.xs}) {
    height: 340px;
    margin-top: -6.5rem;
  }
`

export default Hero
